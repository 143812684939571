.navbar > .container {
  max-width: 100%;
  margin: 0;
  padding: 10;
}

.navbar {
  margin: 0;
  padding: 0;
}

.questionNumber {
  width: 50px;
  height: 50px;


  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #cff4fc;

  border:#b6effb solid 1px;
  border-radius: 50px;

  float:left;
  margin-right: 35px;
}
.questionNumberBig > a {
  font-size: 45px;
}
.questionNumberSmall > a {
  font-size: 40px;
}
.noLineBreak {
  float:left;
}

.small {
  width: 24px;
  height: 24px;

}

.content {
  margin: 50px
}

.alert {
  width:100%
}

.loginForm{
  width:25%;
  margin: 0 auto;
}

.mainH1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 100px !important;
}

.card {
  margin-bottom: 14px;
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar {
  height: 100%;
  background-color: black;
}

.navbar p {
  color: gray;
} */
